import React from 'react';
import ReactDOM from 'react-dom';
import AppF1 from './AppF1';
import 'normalize.css'
import './styles/styles.scss';

ReactDOM.render(
  <React.StrictMode>
    <AppF1 />
  </React.StrictMode>,
  document.getElementById('root')
);
